import React,{useEffect,useState} from 'react';
import { Collapse ,Button,BackTop} from 'antd';
import {ChartNP,Loader,Party} from '../index';
import { useParams ,Link} from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';
import axios from 'axios';
import i18next from 'i18next';
import './SeatsDetail.css';
import {VerticalAlignTopOutlined } from '@ant-design/icons'
import {Johor,Kedah,Kelantan,KL,Melaka,Sembilan,Pahang,Penang,Perak,Perlis,Sabah,Sarawak,Selangor,Terengganu} from '../../map';
import { Gpt } from 'react-gpt-ads';
import zhConvertor from 'zhconvertor';

const {Panel}=Collapse;
const SeatsDetail = () => {
  
  const {parliment,state}=useParams();
  const [seatCode,setSeatCode]=useState(parliment);
  const [data,setData]=useState([]);
  const [expandAll,setExpandAll]=useState(false);
  const [done,setDone]=useState(undefined);
  const [isOfficial,setIsOfficialResult]=useState(true);
  const [seat,setSeat]=useState('');

  const fetchData = async(state,seatCode)=>{
    try{
      axios.get(`seatinfo.php?seat=${state}&code=${seatCode}`).then((response)=>{
        const datas=response.data
        
        setData(datas);
        setDone(true);

        // for each candidate, check if they are official, if one of them is not official, then the result is not official, break the loop after setIsOfficialResult(false)

        for(let i = 0; i < datas.length; i++){
          if(datas[i].is_o == 0){
            setIsOfficialResult(false);
            // break if one of the candidate is not official
            break;
          }
        }

        return datas;
      })
    }catch(error){
      console.log(error)
    }
  }
  //expand
  const activeKey=()=>{
      const arr=[];
      for (let i=0 ;i<data.length;i++){
         arr[i]=i;
      }
      setSeat(arr);
    
  }
  const expandFunctions=()=>{
    setExpandAll(!expandAll);
    if(expandAll===true){
      const arr=[];
      for (let i=0 ;i<data.length;i++){
        arr[i]=i;
      }
      setSeat(arr);
    }else{
      setSeat([]);
    }
  }
  //end expand

  useEffect(()=>{
    window.scrollTo(0, 0)
    setSeatCode(parliment)
    fetchData(state,seatCode)
    parlimentStateFunction()
    //console.log('Fetching')
    // const interval=setInterval(()=>{
    //   fetchData(state,seatCode)
    // },2000)
    // return ()=>clearInterval(interval)
  },[state,seatCode,parliment])


useEffect(()=>{
  if(data&&expandAll===false){
    activeKey()
  }
},[data])

  const convertName = (state) => {
    switch (state) {
      case "johor":
        state = "柔佛";
        return state;
        
      case "kedah":
        state = "吉打" + "（36 席）";
        return state;
        
      case "penang":
        state = i18next.t("槟城") + "（40 席）";
        return state;
        
      case "kelantan":
        state = i18next.t("吉兰丹") + "（45 席）";
        return state;

      case "melaka":
        state = i18next.t("马六甲");
        return state;
        
      case "negerisembilan":
        state = i18next.t("森美兰") + "（36 席）";
        return state;
        
      case "selangor":
        state = i18next.t("雪兰莪") + "（56 席）";
        return state;
        
      case "sabah":
        state = "沙巴";
        return state;
        
      case "terengganu":
        state = i18next.t("登嘉楼") + "（32 席）";
        return state;
        
      case "sarawak":
        state = i18next.t("砂拉越");
        return state;
        
      case "perlis":
        state = i18next.t("玻璃市");
        return state;
        
      case "perak":
        state =i18next.t("霹雳");
        return state;
        
      case "pahang":
        state = "彭亨";
        return state;
        
      case "kl":
        state =i18next.t("联邦直辖区");
        return state;
        
      default:
        state = "error";
        return state;
    }
  };
  const parlimentStateFunction=(e)=>{
    setExpandAll(false);
  }
  
  // const loadComponent=(name)=>{
  //   const Component=React.lazy(()=>
  //      import (`../../map/${name}`)
  //   );
  //   return Component
  // }
  // const Component=loadComponent(state)
  
  const topStyle={
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 20,
    backgroundColor: 'red',
    color: '#fff',
    textAlign: 'center',
    fontSize: 20,
  }

  if (!done) return <Loader/>;
  
  return (
    <> 
      <div className="div_main">
        <div className='title_seat'>
          <h1 className='title'>{convertName(state)}</h1>
          {
            /*
            !isOfficial &&
              <h3>{localStorage.getItem('lang') != 'zh_TW' ?zhConvertor.t2s("非正式成绩") : "非正式成绩"}</h3>
              */
             
          }
        </div>
        {/*
        <div className='button_expand'>
          <Button type="primary" value="p" danger size='large' onClick={parlimentStateFunction} style={{marginRight:'10px',zIndex:'10'}}><Link to={`../seats/${state}/p`}>国会议席</Link></Button>
          {(state==='pahang' || state==='perlis' || state==='perak') &&<Button type="primary" value="n" style={{zIndex:'10'}}danger size='large' onClick={parlimentStateFunction}><Link to={`../seats/${state}/n`}>州议席</Link></Button>}
        </div>
         */}
        <div className='chartNP'> 
          <ChartNP state={state} code={seatCode}/>
        </div>
        <div className='table_party'>
          <Party/>
        </div>
        <Gpt 
              adUnit="/2163006/election2023_top"
              name="div-gpt-ad-1666944904129-0"
              size={[[300, 250],[336, 280], 'fluid']}
          />
        <div className='seat_map'>
          <h1 >{convertName(state)+"-地图"}</h1>
          <div className='map'>
            {state==='johor'&&<Johor code={seatCode} state={state}/>}
            {state==='kedah'&&<Kedah code={seatCode} state={state}/>}
            {state==='kelantan'&&<Kelantan code={seatCode} state={state}/>}
            {state==='kl'&&<KL code={seatCode} state={state}/>}
            {state==='melaka'&&<Melaka code={seatCode} state={state}/>}
            {state==='negerisembilan'&&<Sembilan code={seatCode} state={state}/>}
            {state==='pahang'&&<Pahang code={seatCode} state={state}/>}
            {state==='penang'&&<Penang code={seatCode} state={state}/>}
            {state==='perak'&&<Perak code={seatCode} state={state}/>}
            {state==='perlis'&&<Perlis code={seatCode} state={state}/>}
            {state==='sabah'&&<Sabah code={seatCode} state={state}/>}
            {state==='sarawak'&&<Sarawak code={seatCode} state={state}/>}
            {state==='selangor'&&<Selangor code={seatCode} state={state}/>}
            {state==='terengganu'&&<Terengganu code={seatCode} state={state}/>}
          </div>
        </div>
        <div className='button_expand'>
          <Button type="primary"  style={{backgroundColor:'grey',borderRadius:'10px',borderColor:'white'}}  onClick={expandFunctions}>{expandAll ? '显示全部 ' : '隐藏全部'} </Button>
        </div>
        <div className='seat_info'>
            {data.map((cand,index)=>
              {
                return <section id={"s"+cand.seatname} key={index}>
                  <Collapse activeKey={seat} onChange={setSeat} key={index} className='collapse_seat' >
                    <Panel className='panel_seat' header={cand.seatname +"  "+ cand.ednamec +" "+ cand.edname} key={index} >
                      {
                        cand.is_o == 0 && cand.has_winner == 1 &&
                        <h3 className="hint_unofficial">*{localStorage.getItem('lang') != 'zh_TW' ?zhConvertor.t2s("非官方成績") : "非官方成績"}</h3>
                      }
                    {/*HTMLReactParser(cand.candidate+cand.majority+cand.total_votes+cand.electorate+cand.reg_percent+cand.last_result)*/
                    HTMLReactParser(cand.candidate+cand.majority+cand.total_votes+cand.electorate+cand.reg_percent+cand.last_result)}
                    </Panel>
                  </Collapse>
              </section>
                
              }
            )}
        </div>
        <BackTop>
          <div style={topStyle}><VerticalAlignTopOutlined /></div>
        </BackTop>
      </div>
    </>
  )
}
export default SeatsDetail