import {Chart,AllParty,StateParty,Ad4} from '../';
import { Suspense } from 'react';
import './Seats.css';
import { Gpt } from 'react-gpt-ads';
const Seats = () => {
  
  // if (!done) return <Loader/>;
  return (
    <> 
    
      {/*<div className='chart'>
        <Chart />
      </div>*/}
      <div className="home-page">
        <div className='table_party'>
          <AllParty/>
        </div>
        <Gpt 
              adUnit="/2163006/election2023_top"
              name="div-gpt-ad-1666944904129-0"
              size={[[300, 250],[336, 280], 'fluid']}
          />
        <div className='state_main' style={{textAlign:'center',marginTop:'3em'}}>
          <StateParty/>
        </div>  
      </div>
    </>
  )
}
export default Seats