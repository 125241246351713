import { useEffect,useState } from 'react';
import {ChartEmbed} from '../';
import moment from 'moment';
import i18next from 'i18next';
import 'moment/locale/zh-cn';
import '../Home/Home.css';
moment().locale('zh-cn')

const Embed = () => {
 
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  

  function getCurrentDimension(){
    return {
      	width: window.innerWidth,
      	height: window.innerHeight
    }
  }
  
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    
    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])
  
  return (
    <>
    <div className="embed-page">
        <a href="https://prn2023.orientaldaily.com.my?utm_source=gam" target="_top" style={{textDecoration:"none", color:"#666"}}>
        <div className="embed_title">2023六州选举</div>
        <div>
            
            <ul className='chart'>
            <li className='eachchart'>
                <div className='div_eachchart'>
                <img className='chart_flag' src='/flags/flag_selangor.png' />
                <div className='state_name'>{i18next.t('state_4')}（56 席）</div>
                <ChartEmbed
                    stateId={4} //selangor
                    totalSeats={56}
                />
                </div>
            </li>
            { /*<li className='eachchart'>
                <div className='div_eachchart'>
                <img className='chart_flag' src='/flags/flag_n9.png' />
                <div className='state_name'>{i18next.t('state_5')}（36 席）</div>
                <ChartEmbed
                    stateId={5} //n9
                    totalSeats={36}
                />
                </div>
  </li>*/}
            <li className='eachchart'>
                <div className='div_eachchart'>
                <img className='chart_flag' src='/flags/flag_penang2.png' />
                <div className='state_name'>{i18next.t('state_6')}（40 席）</div>
                <ChartEmbed
                    stateId={6} //penang
                    totalSeats={40}
                />
                </div>
            </li>
            <li className='eachchart'>
                <a style={{fontSize:"1.3em", margin:"0 2em 0 1em", fontWeight: "bold", lineHeight: "249px", color:"#666", display:"inline-block", width: "80px"}} target="_top" href="https://prn2023.orientaldaily.com.my?utm_source=gam">更多 >></a>
            </li>
            { /*
            <li className='eachchart'>
                <div className='div_eachchart'>
                <img className='chart_flag' src='/flags/flag_kedah.png' />
                <div className='state_name'>{i18next.t('state_3')}（36 席）</div>
                <ChartEmbed
                    stateId={3} //kedah
                    totalSeats={36}
                />
                </div>
            </li>
            <li className='eachchart'>
                <div className='div_eachchart'>
                <img className='chart_flag' src='/flags/flag_kelantan.png' />
                <div className='state_name'>{i18next.t('state_1')}（45 席）</div>
                <ChartEmbed
                    stateId={1} //kelantan
                    totalSeats={45}
                />
                </div>
            </li>
            <li className='eachchart'>
                <div className='div_eachchart'>
                <img className='chart_flag' src='/flags/flag_terengganu.png' />
                <div className='state_name'>{i18next.t('state_2')}（32 席）</div>
                <ChartEmbed
                    stateId={2} //terengganu /seats/terengganu/n
                    totalSeats={32}
                />
                </div>
            </li>
            */ }
            
            </ul>
            </div>
            
        </a>
    </div>
    
    </>
  )
}
export default Embed 