import {Nav,Home,Seats,Embed,SeatsDetail,Search,Footer} from './components/index';
import {Routes,Route, useLocation} from 'react-router-dom';

const App = () => {
  return (
    <div className='app'>
  
      { useLocation().pathname != "/embed" ? 
      <div className="navbar">
        <Nav/>
      </div>
       : null}
      <div className="main">
      { useLocation().pathname != "/embed" ?
      <Search/> : null }
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/embed" element={<Embed/>}/>
          <Route path="/seats" element={<Seats/>}/>
          <Route path="/seats/:state" element={<SeatsDetail/>}/>
          <Route path="/seats/:state/:parliment" element={<SeatsDetail/>}/>
          <Route path="*" element={<p style={{textAlign:'center'}}>Error 404</p>}/>
        </Routes>
      </div>
      { useLocation().pathname != "/embed" ? 
      <div className='footer'>
        <Footer/>
      </div>
      : null}
    </div>
  )
}

export default App