import React ,{useState,useEffect}from 'react'
import {Select } from 'antd';
import { useResultContext } from '../../context/ResultContextProvider';
import { HashLink } from 'react-router-hash-link';
import i18next from 'i18next';
//import {SearchOutlined} from '@ant-design/icons';
export const Search = () => {
  const {Option}=Select;
  const {result,fetchData}=useResultContext();
  const [text,setText]=useState('');

    useEffect(()=>{
      fetchData(`?name=${text}`);
    },[text])
  
    const onSearch = (value) => {
      setText(value);
    };
    
    if(!result) return [];
    const options=result.map((data)=><Option value={data.search} key={data.search}><HashLink to={`/seats/${data.state}/${data.seatNP.toLowerCase()}/#s${data.seatNP}${data.seatno}`}>{data.seatNP+data.seatno+" "+data.name+" "+data.cname}</HashLink></Option>)
    
  return (
    <div className="div_search">
        <Select
          showSearch
          placeholder={i18next.t("搜索议席")}
          showArrow={false}
          onSearch={onSearch}
          style={{width:300}}
          bordered={true}
          allowClear={true}
          notFoundContent={null}
          >
          {text.length>0 && options}
        </Select>
        
    </div>
  )
}
