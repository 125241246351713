import { Bar } from '@ant-design/plots';
import { useEffect, useState } from 'react';
import {Loader} from '../';
import axios from 'axios';
import i18next from 'i18next';

const Chart = (props) => {
  const [info,setInfo]=useState();
  const [done,setDone]=useState(undefined);
  const [colors,setColors]=useState([]);
  const [colorsText,setColorsText]=useState();

  const fetchChart=async()=>{   
    const res=await axios.get(`chart.php?state=${props.stateId}`)
    
      const data=res.data;
      setInfo(data);

      const arr_colors=[];
      const arr_text=[];
      if(data){
        for(let i=0;i<data.length;i++){
          arr_colors[i]=data[i].colors;
          arr_text[i]={
            type: 'text',
            position: [data[i].name,200],
            content: data[i].value,
            style: {
              fontSize:16,
              fill: 'white',
            },
            background: {
              padding: 4.7,
              style: {
                radius: 4,
                fill: data[i].colors,
              },
            },
            
          }
        }
        setColors(arr_colors)
        setColorsText([...arr_text,{
          type: 'line',
          start:['start', Math.floor(props.totalSeats/2+1)],
          end:['end', Math.floor(props.totalSeats/2+1)],
          text:{
            content: Math.floor(props.totalSeats/2 +1) + " " + i18next.t("content"),
            autoRotate:false,
            position:'left',
            offsetX:-10,
            offsetY:-305,
            style:{
              fontWeight:400, 
              fontSize:16,  
            }
          },
          style: {
            stroke: 'red',
            lineDash: [6, 4],
          },
        },
       
      
      ])

        setDone(true)
      }
    }
  useEffect(()=>{
    fetchChart()
    // const interval=setInterval(()=>{
    //   fetchChart()
    // },15000)
    // return ()=>clearInterval(interval)
  },[props.stateId, props.totalSeats])
  
  //console.log("info " + JSON.stringify(info));
  const config = {
    
    data:info,
    height:400,
    padding:50,
    xField: 'value',
    yField: 'name',
    seriesField: 'name',
    legend:false,
    barWidthRatio: 0.8,
    meta:{ value:{ max:80, tickCount:5, maxLimit:props.totalSeats, } },
    color: colors, 
    barBackground: { style: { fill: '#d9d9d9', } },
    annotations:colorsText,
     label: {
      content: info ? info.value : "",
       position: 'right',
       style: {
         fill: '#000000',
         opacity: 0.9,
         fontSize:14,
        
       },
     },
    
  };
  if(!done)return <Loader/>
  return (
    <>
      {info.length?<Bar {...config} />:null}
    </>
  );
};
export default Chart