import React from 'react'
import './Footer.css'
const Footer = () => {
    
  return (
    <div className="footer">
        <p className='small-text'>© 2022 馬來西亞東方日報 版權所有 不得轉載</p>
    </div>
  )
}

export default Footer