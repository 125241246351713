import { useEffect,useState,Suspense } from 'react';
import {Chart,Ad1,Ad2,Ad3,Ad4,Loader,Video1,Video2} from '../';
import {Card,Row,Typography,Button,Tag} from 'antd';
import moment from 'moment';
import i18next from 'i18next';
import ErrorImage from '../../img/og-image.jpg'
import axios from 'axios';
import 'moment/locale/zh-cn';
import './Home.css';
import { Gpt } from 'react-gpt-ads';
import zhConvertor from 'zhconvertor';

moment().locale('zh-cn')

const {Text,Title}=Typography;

const Home = () => {
  const [article,setArticle]=useState([]);
  const [load,setLoad]=useState(25);
  const [done,setDone]=useState(undefined);
  const [iframe1,setIFrame1]=useState('');
  const [iframe2,setIFrame2]=useState('');
  const [iframe3,setIFrame3]=useState('');
  const [focusSeat,setFocusSeat]=useState('');
  const [focusHuman,setFocusHuman]=useState('');
  const replaceErrorImg=e=>{
    e.target.onerror=null;
    e.target.src=ErrorImage;
  }
 
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const fetchData=async()=>{
      
    var rand = Math.random() * 100;

    axios.get('article.php?i=' + rand).then((response)=>{
        const articleData=response.data
        setArticle(articleData);
        setDone(true);
      })

      // get content from an url using Promise
      
    await fetch(
      "https://prn2023.orientaldaily.com.my/admin/cache/pinvid.txt?i=" + rand 
    ).then(response => response.text()).then(data => { setIFrame1(data)});
    await fetch(
      "https://prn2023.orientaldaily.com.my/admin/cache/pinvid2.txt?i=" + rand
      ).then(response => response.text()).then(data => { setIFrame2(data)});
    await fetch(
        "https://prn2023.orientaldaily.com.my/admin/cache/album.txt?i=" + rand
        ).then(response => response.text()).then(data => { setIFrame3(data)});
    await fetch(
      "https://prn2023.orientaldaily.com.my/admin/cache/focusseat.txt?i=" + rand
      ).then(response => response.text()).then(data => { 
        
        var data_arr = data.split(/\r?\n/);
        /*
        result.forEach(element => {
          console.log("element: " + element);
        });
        */
       
        setFocusSeat(data_arr);
      });
    await fetch(
        "https://prn2023.orientaldaily.com.my/admin/cache/focushuman.txt?i=" + rand
        ).then(response => response.text()).then(data => { 
          
        var data_arr = data.split(/\r?\n/);
        setFocusHuman(data_arr);
        });
  }

  function getCurrentDimension(){
    return {
      	width: window.innerWidth,
      	height: window.innerHeight
    }
  }
  useEffect(()=>{
    fetchData();
  },[])
  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    
    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])
  if (!done) return <Loader/>;
  return (
    <>
    <div className="home-page">
      {window.innerWidth <= 480 ? <img className="mainbanner" src="/flags/mainbanner_mobile.png" /> : <img className="mainbanner" src="/flags/mainbanner_desktop.jpg" />
      }
      <div>
        
        <ul className='chart'>
        <li className='eachchart'>
            <div className='div_eachchart'>
              <img className='chart_flag' src='/flags/flag_selangor.png' />
              <div className='state_name'><a href="/seats/selangor/n">{i18next.t('state_4')}</a>（56 席）</div>
              <Chart
                stateId={4} //selangor
                totalSeats={56}
              />
            </div>
          </li>
          <li className='eachchart'>
            <div className='div_eachchart'>
              <img className='chart_flag' src='/flags/flag_n9.png' />
              <div className='state_name'><a href="/seats/negerisembilan/n">{i18next.t('state_5')}</a>（36 席）</div>
              <Chart
                stateId={5} //n9
                totalSeats={36}
              />
            </div>
          </li>
          <li className='eachchart'>
            <div className='div_eachchart'>
              <img className='chart_flag' src='/flags/flag_penang2.png' />
              <div className='state_name'><a href="/seats/penang/n">{i18next.t('state_6')}</a>（40 席）</div>
              <Chart
                stateId={6} //penang
                totalSeats={40}
              />
            </div>
          </li>
          <li className='eachchart'>
            <div className='div_eachchart'>
              <img className='chart_flag' src='/flags/flag_kedah.png' />
              <div className='state_name'><a href="/seats/kedah/n">{i18next.t('state_3')}</a>（36 席）</div>
              <Chart
                stateId={3} //kedah
                totalSeats={36}
              />
            </div>
          </li>
          <li className='eachchart'>
            <div className='div_eachchart'>
              <img className='chart_flag' src='/flags/flag_kelantan.png' />
              <div className='state_name'><a href="/seats/kelantan/n">{i18next.t('state_1')}</a>（45 席）</div>
              <Chart
                stateId={1} //kelantan
                totalSeats={45}
              />
            </div>
          </li>
          <li className='eachchart'>
            <div className='div_eachchart'>
            <img className='chart_flag' src='/flags/flag_terengganu.png' />
            <div className='state_name'><a href="/seats/terengganu/n">{i18next.t('state_2')}</a>（32 席）</div>
              <Chart
                stateId={2} //terengganu /seats/terengganu/n
                totalSeats={32}
              />
            </div>
          </li>
          
          
        </ul>
    
  </div>
          <Suspense fallback={<Loader/>}>
            
          </Suspense>
          <Gpt 
              adUnit="/2163006/election2023_top"
              name="div-gpt-ad-1666944904129-0"
              size={[[300, 250],[336, 280], 'fluid']}
          />

          {article.slice(0,load).map((art,index)=>(
            <Row key={index}>
              {index == 7 ? 
                <Gpt 
                  adUnit="/2163006/election_336x280_300x250"
                  name="div-gpt-ad-1599627962119-0"
                  size={[[300, 250],[336, 280]]}
                  style={{margin:"1em auto"}}
              /> : null
              }
              {index == 14 ? 
                <Gpt 
                  adUnit="/2163006/election_336x280_300x250_2"
                  name="div-gpt-ad-1599823689403-0"
                  size={[[300, 250],[336, 280]]}
              /> : null
              }
              {index == 21 ? 
                <Gpt 
                  adUnit="/2163006/election_336x280_300x250"
                  name="div-gpt-ad-1599907131243-0"
                  size={[[300, 250],[336, 280]]}
              /> : null
              }
              {/*index===7 && <Ad1/>}
              {index===14 && <Ad2/>}
          {index===21 && <Ad3/>*/}
              

              {index == 2 && focusSeat != "" && (
                <div className="focus-section">
                  <div className="section-title">{i18next.t("选战热话")}</div>
                  <ul className="focus-section-ul">
                  {
                    focusSeat.map((eachFocus, i) => {
                      console.log("eachFocus: "  + eachFocus);
                      return(
                      <li><a href={"https://www.orientaldaily.com.my/news/browse/" + i18next.t(eachFocus)}>
                        <div key={i} className="each-focus">{localStorage.getItem('lang') != 'zh_TW' ?zhConvertor.t2s(eachFocus) : eachFocus}</div>
                        </a>
                      </li>)
                    })
                    
                  }
                  </ul>
                </div>
              )}

              {index == 2 && iframe1 != "" && (
                <div className="player-wrapper" dangerouslySetInnerHTML={{__html: iframe1}}>
                </div>
              )}
              {index == 6 && iframe2 != "" && (
                <div className="player-wrapper" dangerouslySetInnerHTML={{__html: iframe2}}>
                </div>
              )}
              {index == 12 && iframe2 != "" && (
                <div className="album-wrapper" dangerouslySetInnerHTML={{__html: iframe3}}>
                </div>
              )}

              {/*index == 5 && focusHuman != "" && (
                <div className="focus-section">
                  <div className="section-title">{i18next.t("焦点人物")}</div>
                  <ul className="focus-section-ul">
                  {
                    focusHuman.map((eachFocus, i) => {
                      return(
                        <li><a href={"https://www.orientaldaily.com.my/news/browse/" + i18next.t(eachFocus)}>
                          <div key={i} className="each-focus">{eachFocus}</div>
                          </a>
                        </li>)
                    })
                    
                  }
                  </ul>
                </div>
                )*/}

                <Card hoverable className="article-news">
                <a href={art.url} target="_blank" rel="noreferrer">
                  <div className="news-image-container">
                      <div>
                        <img src={art.picurl} className='news-image' alt={art.id} onError={replaceErrorImg}/>
                      </div>
                      <div className='article-text'>
                        <Title className='news-title' level={5}>{art.title}</Title>
                        <Text className='news-date'>{moment(art.date).fromNow()}</Text>
                        {art.pinnews && (<Tag className="pin-news"color="red" style={{marginLeft:"1em",borderColor:'white'}}>{i18next.t("热闻")}</Tag>)}
                        <Text className='news-text' style={{marginTop:'1em'}}>{art.description.length>100 ? `${art.description.substring(0,50)}...` : art.description}</Text>
                      </div>  
                  </div>
                </a>
                </Card>
            </Row>
            )
            )
          }

    </div>
    <div className='load-page'>
      <Button className='load-button' type="primary" style={{marginBottom:'3em',backgroundColor:'#cb0c13',borderColor:'#cb0c13',height:'auto',fontSize:'15px',padding:'14px 50px'}}onClick={()=>setLoad((preLoad)=>preLoad+10)}>更多文章</Button>
    </div>
    </>
  )
}
export default Home 