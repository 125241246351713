import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";
//import antdesign cdn
import 'antd/dist/antd.min.css';
import {ResultContextProvider} from './context/ResultContextProvider'
import axios from 'axios';
import 'i18next';
import i18next from 'i18next';

axios.defaults.baseURL='https://prn2023.orientaldaily.com.my';

const lang=localStorage.getItem('lang') || 'zh_CN';
axios.defaults.headers.common['Accept-Language']=lang
i18next.changeLanguage(lang);

ReactDOM.render(
<ResultContextProvider>
  <BrowserRouter>
    <App/>
  </BrowserRouter>
</ResultContextProvider>
,document.getElementById('root'))