import i18next from 'i18next';
import cnJson from './translation/cn.json';
import twJson from './translation/tw.json';

const resources={
  zh_CN:{
    translation:cnJson
  },
  zh_TW:{
    translation:twJson
  }
}

i18next.init({
  lng:'zh_CN',
  debug:false,
  resources
})

export default i18next;