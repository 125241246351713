import React,{useState,createContext,useContext} from 'react'
import '../i18next';
import axios from 'axios'

const ResultContext=createContext();

export const ResultContextProvider = ({children}) => {
  const [result,setResult]=useState('');
  const [isLoading,setIsLoading]=useState(false);
  
  const fetchData=async(searchTem)=>{
    axios.get((`/searchAPI.php${searchTem}`)).then((response)=>{
      const searchData=response.data
      setResult(searchData);
      setIsLoading(false);
    })
  }

  return (
    <ResultContext.Provider value={{result,isLoading,fetchData}}>
        {children}
    </ResultContext.Provider>
  )
}
export const useResultContext=()=>useContext(ResultContext);