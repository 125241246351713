import { Table } from 'antd';
import React,{useEffect,useState} from 'react'
import axios from 'axios';
import i18next from 'i18next';
import "./AllParty.css";
const AllParty = () => {
    const [allParty,setAllParty]=useState();
    
    const props = {
        loading: false,
        tableLayout:'fixed',
        size: "middle",
        pagination:{ position: ['none'] },
      };


      const columns=[
        {
          dataIndex:["icon"],
          key:'party',
          align:'right',
          render:icon=><img src={icon} alt="icon" style={{width:'25px',height:'25px'}} />
        },
        {
          title:<p style={{fontSize:'16px',fontWeight:'bold',margin:'auto'}}>{i18next.t('联盟')}</p>,
          dataIndex:["party"],
          key:'party',
          align:'left',
          width:'30%',
          render:text=><p style={{fontSize:'16px',fontWeight:'600',margin:'auto'}}>{i18next.t(text)}</p>
        },
        {
          title:<p style={{fontSize:'16px',fontWeight:'bold',margin:'auto'}}>{i18next.t('提名')}</p>,
          dataIndex:"num",
          key:"num",
          align:'center',
          width:'20%',
          render:text=><p style={{fontSize:'16px',fontWeight:'600',margin:'auto'}}>{text}</p>
        },
        {
          title:<p style={{fontSize:'16px',fontWeight:'bold',margin:'auto'}}>{i18next.t('中选')}</p>,
          dataIndex:"win",
          key:"win",
          align:'center',
          width:'20%',
          render:text=><p style={{fontSize:'16px',fontWeight:'600',margin:'auto'}}>{text}</p>

        },
      ]

    const fetchTableData=async(state,parliment)=>{
        axios.get("overallParty.php").then((response)=>{
          const data=response.data
          setAllParty(data);
        })
      }
      useEffect(()=>{
        fetchTableData(); 
        // const interval=setInterval(()=>{
        //   fetchTableData();  
        // },15000)
        // return ()=>clearInterval(interval)
      },[])

  return (
    <div>
        <Table {...props}  columns={columns}  rowKey="key" dataSource={allParty} expandable={{expandedRowRender:(record)=> record.parties.map((subitem,index)=>(
            <table key={index} className="tableParty" >
              <tbody >
                {subitem.nums > 0 ?
                <tr  className="party_rows" key={index}>
                  <td className="td_partyName " style={{width:'40%',height:'50px',textAlign:'left',paddingLeft:'10px'}}>
                    <img src={subitem.icon} alt="icon" style={{width:'25px',height:'25px',marginRight:'10px'}} />{subitem.partys}
                    <img src="https://testelec2022.orientaldaily.com.my/icon/Arrow_03%20copy.png" alt="logo-direction" style={{width:'10px' ,height:'19px',marginRight:'-6px',float:'right',marginTop:'7px'}} />
                  </td>
                  <td className="td_party"> {!subitem.nums ? 0 : subitem.nums} </td>
                  <td className="td_party" style={{paddingLeft:'30px'}}>{!subitem.wins ? 0: subitem.wins}</td>
                </tr>
                : null}
              </tbody>
            </table>
          )) }}/>
      </div>
  )
}

export default AllParty
    
    
    