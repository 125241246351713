export const getTextColor=(rgba)=>{
    rgba = rgba.match(/\d+/g);
    if ((rgba[0] * 0.299) + (rgba[1] * 0.587) + (rgba[2] * 0.114) > 186) {
        return '#666666';
    } else {
        return '#ffffff';
    }
}
export const clearData=()=>{
    const white_color='rgb(255, 255, 255)';
    const black_color='rgb(0,0,0)';
    let addText=document.getElementsByTagName("text");
    let textLength=addText.length;

    for(let x=0;x<textLength;x++){
        addText[x].style.fill=black_color;
    }

    let map = document.querySelectorAll(".map");
    let map_length = document.querySelectorAll(".map").length;
    for(let x=0;x<map_length;x++){
        map[x].style.fill=white_color;
        map[x].style.stroke="";
    }
}
